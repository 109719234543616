<template>
  <div class="text-center">
    <img src="img/address/no-address.png" alt="" class="img-center" />
    <p class="no-delivery mt-2">Delivery service unavailable</p>
    <p class="no-delivery-p-tag mb-0">Please choose another one</p>
    <div class="mt-4 mb-1 delivery-serv-buttn">
      <button
        @click="() => this.$bvModal.show('bv-add-address')"
        type="button"
        class="btn btn-outline-primary"
      >
        <span class="button-text"></span> Add Address
      </button>
      <button
        @click="() => $bvModal.show('bv-modal-example')"
        type="button"
        class="btn btn-outline-primary"
      >
        <span class="button-text"></span>Choose Another
      </button>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
.btn-outline-primary {
  width: 150px;
}
.img-center {
  width: 200px;
}
.no-delivery {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  text-transform: capitalize;
  color: #000000;
}
.no-delivery-p-tag {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.delivery-serv-buttn button {
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  width: 140px;
  font-size: 14px;
}
</style>
